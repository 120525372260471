import { AppointmentStatus } from "@prisma/postgresClient";

export const EnabledAppointmentStatus = [
  AppointmentStatus.AWAITING_PAYMENT,
  AppointmentStatus.PAYMENT_EXPIRED,
  AppointmentStatus.SCHEDULED,
  AppointmentStatus.RESCHEDULED,
  AppointmentStatus.RESCHEDULED_LATE,
  AppointmentStatus.ACTIVE,
  AppointmentStatus.COMPLETED,
] as AppointmentStatus[];

export const cancelledStatuses = [
  AppointmentStatus.CANCELLED,
  AppointmentStatus.CANCELLED_LATE,
] as AppointmentStatus[];

export const upcomingStatuses = [
  AppointmentStatus.SCHEDULED,
  AppointmentStatus.RESCHEDULED,
  AppointmentStatus.RESCHEDULED_LATE,
  AppointmentStatus.AWAITING_PAYMENT,
  AppointmentStatus.PAYMENT_EXPIRED,
] as AppointmentStatus[];

export const plannedStatuses = [
  AppointmentStatus.ACTIVE,
  AppointmentStatus.SCHEDULED,
] as AppointmentStatus[];

export const confirmedStatuses = [
  AppointmentStatus.ACTIVE,
  AppointmentStatus.COMPLETED,
  AppointmentStatus.SCHEDULED,
] as AppointmentStatus[];

export default AppointmentStatus;
