import { EventCard } from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";
import useSessionCard from "./useSessionCard";
import { useSessionCard_session$key as Fragment } from "./__generated__/useSessionCard_session.graphql";

interface Props {
  session: Fragment;
}

export default function SessionCard({
  session,
}: Readonly<Props>): JSX.Element | null {
  const {
    data: {
      applyBackgroundMask,
      buttonLabel,
      careType,
      duration,
      eventDate,
      eventType,
      eventTypeTranslation,
      imageSource,
      isAwaitingPayment,
      isRefunded,
      isSessionActive,
      pendingProgressCheckSurvey,
      professional,
      sessionId,
      title,
    },
    effects: { handleClickButton },
  } = useSessionCard(session);
  const { t } = useTranslation("employeeOneToOnePage", {
    keyPrefix: "yourSessions",
  });

  if (session == null) {
    return null;
  }

  return (
    <EventCard
      applyBackgroundMask={applyBackgroundMask}
      awaitingPayment={
        isAwaitingPayment ? t("sessionCard.awaitingPayment") : null
      }
      buttonLabel={buttonLabel}
      careType={careType}
      date={eventDate}
      duration={duration ? t("sessionCard.duration", { duration }) : undefined}
      eventTypeTranslation={eventTypeTranslation}
      id={sessionId}
      imageSource={imageSource}
      isActive={isSessionActive ? t("sessionCard.now") : undefined}
      onClickButton={handleClickButton}
      pendingProgressCheckSurvey={
        pendingProgressCheckSurvey
          ? t("sessionCard.pendingProgressCheckSurvey")
          : null
      }
      professional={professional}
      {...(isRefunded && { refunded: t("sessionCard.refunded") })}
      title={title}
      type={eventType}
      url={`/sessions/${sessionId}`}
      variant="v2"
    />
  );
}
